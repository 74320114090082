.form {
  input[type='checkbox'] {
    width: 15px;
  }

  &__group {
    margin-bottom: 18px;
    &--password {
      display: flex;
      justify-content: space-between;
      input[type='checkbox'] {
        width: 18px;
        height: 18px;
        margin-top: -12px;
      }
      label {
        padding-left: 10px;
        position: relative;
        top: -4px;
      }
      a {
        color: $primary;
        font-weight: 400;
        font-size: $text-14;
        margin-top: -4px;
      }
    }
  }
  label {
    margin-bottom: 6px;
    color: $gray-dark;
  }
  input {
    width: 100%;
    padding: 12px 10px;
    border: 1px solid $gray-medium;
    border-radius: 2px;
    &:focus {
      outline: none;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
    &.form__error {
      border: 1px solid $secondary;
    }
  }

  &__validation {
    color: $secondary;
    display: block;
    margin-top: 5px;
    font-size: $text-12;
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $gray-medium;
  font-size: $text-14;
  line-height: 1.1;
  font-weight: 400;
  vertical-align: middle;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $gray-medium;
  font-size: $text-14;
  line-height: 1.1;
  font-weight: 400;
  vertical-align: middle;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $gray-medium;
  font-size: $text-14;
  line-height: 1.1;
  font-weight: 400;
  vertical-align: middle;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: $gray-medium;
  font-size: $text-14;
  line-height: 1.1;
  font-weight: 400;
  vertical-align: middle;
}

input[type='number'] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

input:-webkit-autofill {
  background-color: $white !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
  box-shadow: 0 0 0px 1000px $white inset !important;
}
