@import '../vendors/bootstrap-variables';

// Custom Variables
// ----------------
// Fonts
$font-family: 'Roboto', sans-serif;

// Font sizes
$text-14: 1rem;
$text-63: $text-14 * 4.5;
$text-48: $text-14 * 3.429;
$text-24: $text-14 * 1.715;
$text-21: $text-14 * 1.5;
$text-20: $text-14 * 1.429;
$text-18: $text-14 * 1.286;
$text-16: $text-14 * 1.1429;
$text-13: $text-14 * 0.928;
$text-12: $text-14 * 0.858;
$text-10: $text-14 * 0.715;
$text-9: $text-14 * 0.643;
$text-7: $text-14 * 0.5;

$line-height: 1.17;

// Color system
$primary: #0379bf;
$secondary: #e30000;

$primary-light: #a7d7ff;
$primary-dark: #0168a5;
$secondary-dark: #d30000;
$white: #fff;
$black: #000;
$gray-text: #232730;
$gray-base: #eff2f4;
$gray-light: #f8f9fb;
$gray-medium: #afc4d4;
$gray-dark: #717888;

$yellow: #fee25c;
$orange: #ffb817;
$green: #7dc842;
$green-dark: #68ae30;
$blue: #b4d7ec;
$cadet-blue: #acb6bf;
$link-water: #cde4f2;
$diamond: #c4e2f8;
$bitter-sweet: #ff6565;
$blue_light: #4a9ecf;
$blue_lightest: #afd3e9;

$badge-one: #b7b1dd;
$badge-two: #aaeff9;
$badge-three: #ff9a9a;
$badge-four: #ffd474;
$badge-five: #f0cbff;
$badge-six: #ffc890;
$badge-seven: #feb8e5;
$badge-eight: #dc6666;
$badge-nine: #d4f4f4;
$badge-ten: #ffe0e0;
$badge-eleven: #fcf8d3;
$badge-twelve: #d4f4dd;

$link-blue: #0d6efd;
$baby-blue: #d3ecff;
