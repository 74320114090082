@import 'sass/exports/abstracts';

.app-layout {
  &__main {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    height: calc(100% - 108px);
    position: relative;
  }
  &__content {
    width: 100%;
    min-height: calc(100vh - 108px); // 100vh - .header + footer
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
  }

  &__footer {
    margin: 0 0 7px 13px;
    padding: 25px 0 10px 0;
    font-size: $text-10;
    color: $gray-medium;
    font-weight: 300;
    font-family: $font-family;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}
