.primary {
  color: $primary;
  &:hover {
    color: $primary-dark;
  }
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.warning {
  color: $orange;
  &:hover {
    color: darken($orange, 10%);
  }
}

.required {
  color: $secondary;
}

.mt-20 {
  margin-top: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.mb-md-25 {
  @include media-breakpoint-up(md) {
    margin-bottom: 25px !important;
  }
}

.text-right {
  text-align: right;
}

.first-letter-capital {
  &::first-letter {
    text-transform: capitalize;
  }
}

.confirm-dialog {
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 50px 30px 50px;
  position: relative;
  min-width: 300px;
  max-width: 420px;
  min-height: 176px;

  @include media-breakpoint-between(xs, sm) {
    width: 336px;
    min-height: 178px;
    padding: 0;
  }

  @include media-breakpoint-down(md) {
    margin: 120px auto 0;
  }

  @include media-breakpoint-down(sm) {
    min-height: 178px;
    padding: 60px 30px 30px 30px;
  }

  &--larger {
    margin: 60px auto 0;
    height: 340px;
  }

  &--warning {
    i {
      color: $orange;
    }
  }
  &--danger {
    i {
      color: $secondary;
    }
  }
  &--success {
    i {
      color: $green;
    }
  }
  &__wrap {
    display: flex;
    &--text {
      align-items: center;
      justify-content: center;
    }
    i {
      font-size: 36px;
    }
  }

  &__divider {
    width: 320px;
    height: 1px;
    background-color: rgba(175, 196, 212, 0.3);
    margin: 20px 0px;
    border-radius: 2px;
  }

  &__content {
    margin-left: 15px;

    h6 {
      word-wrap: anywhere;
      margin-bottom: 6px;
    }

    ul {
      list-style-type: none;
      margin-left: -95px;
      li {
        font-weight: 400;
        font-size: $text-14;
        display: flex;

        p {
          font-weight: 300;
          font-size: $text-10;
          color: $gray-dark;
          padding-left: 10px;
        }
      }
    }
    .btn__wrap {
      margin: 20px 0 0;
      @include media-breakpoint-down(sm) {
        margin: 20px 0 0 -50px;
      }
    }

    span {
      font-weight: 700;
    }
  }
  &__note {
    font-size: $text-12;
    color: $gray-dark;
  }
  &__select {
    margin: 25px 0 20px;
    select {
      width: 100%;
      padding: 10px;
    }
  }

  .tag {
    margin-top: 3px;
  }
}

.input-base {
  padding: 7px 12px;
  line-height: 1;
  border-radius: 2px;
  border: 1px solid $gray-dark;
  background-color: $white;
  color: $gray-text;
  font-size: $text-14;
  font-weight: 400;

  &::placeholder {
    color: $gray-medium;
    @include media-breakpoint-down(md) {
      font-size: $text-16;
    }
  }

  &:focus,
  &:focus-visible {
    border: 1px solid $primary-dark;
  }

  &:focus-visible {
    outline: none;
  }
}

.dashboard-filter-all-label {
  color: $gray-dark;
  font-size: $text-14;
  font-weight: 500;
  text-transform: none;
}

.styled-scrollbar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 100%;
  }

  &::-webkit-scrollbar-track {
    background: $white;
    margin: 5px 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-medium;
    border-radius: 100vw;
  }
}

.position-relative {
  margin-bottom: 10px;
}
